<template>
  <div>
    <div v-for="(stock, index) in value" :key="index" class="stock-room">
      <h3 class="has-text-primary has-text-weight-bold is-size-4 m-b-2x">
        {{ stock.category }}
      </h3>
      <div class="stock-items">
        <a-table
          :columns="columns"
          :data-source="stock.collection"
          row-key="id"
          :pagination="false"
        >
          <template #name="{ record }">
            {{ record.name }}
          </template>
          <template #stock="{ record }">
            {{ record.quantity }}
          </template>
          <template #use="{ record }">
            <a-input-number
              v-model:value="record.use"
              size="large"
              class="custom-input-number"
              :min="0"
              :max="record.quantity"
              :step="1"
            />
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<i18n>
{
"en": {
"Item Name": "Item Name",
"Stock": "Stock",
"Use": "Use"
},
"ja": {
"Item Name": "アイテム名",
"Stock": "在庫",
"Use": "用途"
}
}
</i18n>

<script>
export default {
  name: 'Stock',
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      columns: [
        {
          title: this.$t('Item Name'),
          dataIndex: 'name',
          slots: { customRender: 'name' },
        },
        {
          title: this.$t('Stock'),
          dataIndex: 'quantity',
          slots: { customRender: 'quantity' },
          width: '120px',
        },
        {
          title: this.$t('Use'),
          dataIndex: 'use',
          slots: { customRender: 'use' },
          width: '200px',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.stock-room {
  margin-bottom: 30px;
}
</style>
