<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column">
              <h2 class="is-3 title">
                {{ $t('Stock Controls') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container">
        <div class="columns is-mobi">
          <div class="column">
            <SearchForm @filter="fetchStocks" />
          </div>
          <div v-permission="['add-stock-control']" class="column is-narrow">
            <ModalWide>
              <template #default="{ hide }">
                <StockForm
                  @close="
                    () => {
                      hide();
                      fetchStocks();
                    }
                  "
                  @cancel="hide"
                />
              </template>
              <template #handler="{ show }">
                <a class="ant-btn ant-btn-lg ant-btn-primary" @click="show">
                  {{ $t('Add Stock Control') }}
                </a>
              </template>
            </ModalWide>
          </div>
        </div>
        <a-table
          row-key="id"
          :columns="columns"
          :data-source="list"
          :loading="isFetching"
          :custom-row="rowClicked"
          :pagination="pagination"
          @change="handleTableChange"
        >
          <template #id="{ text }">
            {{ text }}
          </template>
          <template #name="{ record }">
            {{ record.name }}
          </template>
          <template #propertyRoom="{ record }">
            <ul
              v-if="record.rooms.length"
              class="property-room-list"
            >
              <li
                v-for="n in record.rooms"
                :key="n"
              >
                {{ n.property.abbreviation }}, {{ n.name }}
              </li>
            </ul>
            <Modal>
              <template #default="{ hide }">
                <AddRoomForm
                  :value="record"
                  @close="
                    () => {
                      hide();
                      fetchStocks();
                    }
                  "
                  @cancel="hide"
                />
              </template>
              <template #handler="{ show }">
                <a v-permission="['modify-stock-control']" @click.stop.prevent="show">
                  {{ $t('Add Room') }}
                </a>
              </template>
            </Modal>
          </template>
        </a-table>
      </div>
    </div>
    <ModalWide ref="editStockModal">
      <template #default="{ hide }">
        <StockForm
          :value="selectStock"
          @close="
            () => {
              hide();
              fetchStocks();
            }
          "
          @cancel="hide"
        />
      </template>
    </ModalWide>
  </div>
</template>

<i18n>
{
"en": {
"Stock Controls": "Stock Controls",
"Add Stock Control": "Add Stock Control",
"Add Room": "Add Room",
"Stock Control Name": "Stock Control Name",
"Property, Room Name": "Property, Room Name"
},
"ja": {
"Stock Controls": "在庫コントロール",
"Add Stock Control": "在庫コントロールを追加",
"Add Room": "部屋を追加",
"Stock Control Name": "在庫コントロール名",
"Property, Room Name": "物件、部屋名"
}
}
</i18n>

<script>
import groupBy from 'lodash/groupBy';
import list from '@/views/stocks/mixins/list';
import SearchForm from '@/views/stocks/components/SearchForm';
import Modal from '@/components/Modal';
import StockForm from '@/views/stocks/components/Form';
import AddRoomForm from '@/views/stocks/components/AddRoomForm';
import ModalWide from '@/components/ModalWide';

export default {
  name: 'DefaultList',
  components: {
    ModalWide,
    AddRoomForm,
    StockForm,
    Modal,
    SearchForm,
  },
  mixins: [list],
  data() {
    return {
      columns: [
        {
          title: '#',
          dataIndex: '_id',
          key: '_id',
          slots: { customRender: 'id' },
        },
        {
          title: this.$t('Stock Control Name'),
          dataIndex: 'name',
          slots: { customRender: 'name' },
        },
        {
          title: this.$t('Property, Room Name'),
          dataIndex: 'propertyRoom',
          key: 'propertyRoom',
          slots: { customRender: 'propertyRoom' },
        },
      ],
    };
  },
  mounted() {
    this.fetchStocks();
  },
  methods: {
    rowClicked(record) {
      return {
        onClick: async () => {
          this.$store.commit('SHOW_FULLSCREEN_LOADER');

          const data = await this.$store.dispatch('stocks/get', record.id);
          const grouped = groupBy(data.items, 'category');

          const items = Object.keys(grouped).map((category) => {
            const collection = grouped[category];

            return { collection, category };
          });

          this.$store.commit('HIDE_FULLSCREEN_LOADER');

          this.selectStock = {
            id: data.id,
            name: data.name,
            items,
          };
          this.$refs.editStockModal.show();
        },
      };
    },
  },
};
</script>
