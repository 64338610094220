<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="m-b">
            <router-link :to="{ name: 'stocks' }" class="has-text-weight-bold has-text-white">
              <IconBase width="14" height="12" icon-name="back" class="m-b-0 m-r-2x">
                <IconBack />
              </IconBase>
              <span>{{ $t('Stock Controls') }}</span>
            </router-link>
          </div>
          <div class="columns is-mobile">
            <div class="column">
              <h2 class="is-3 title">
                {{ room && room.name }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section">
      <div v-if="stocks" class="container">
        <div v-for="(stock, index) in stocks" :key="index">
          <Stock :value="stock.items" />
        </div>
        <div class="has-text-right stock-footer">
          <a-button
            :disabled="loading"
            :size="size"
            class="button-secondary-outline m-r-3x"
            style="min-width: 150px"
            @click="resetFields"
          >
            {{ $t('Reset') }}
          </a-button>
          <a-button
            :loading="loading"
            :size="size"
            style="min-width: 150px"
            type="primary"
            @click="onSubmit"
          >
            {{ $t('Save') }}
          </a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n>
{
"en": {
"Stock Controls": "Stock Controls",
"Reset": "Reset",
"Save": "Save",
"success":"Stock updated."
},
"ja": {
"Stock Controls": "在庫コントロール",
"Reset": "リセット",
"Save": "保存",
"success":"在庫更新。"
}
}
</i18n>

<script>
import IconBack from '@/components/icons/IconBack';
import Stock from '@/views/housekeeper-tasks/components/Stock';
import roomStocks from '@/views/stocks/mixins/room-stocks';

export default {
  name: 'RoomStocks',
  components: {
    IconBack,
    Stock,
  },
  mixins: [roomStocks],
  data() {
    return {};
  },
  mounted() {
    this.fetchRoomStocks();
  },
  methods: {
    async fetchRoomStocks() {
      this.room = await this.$store.dispatch('rooms/get', { id: this.$route.params.id });

      await this.fetchData();
    },
  },
};
</script>

<style scoped></style>
