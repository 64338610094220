<template>
  <component :is="stockListComponent" />
</template>

<script>
import DefaultList from '@/views/stocks/DefaultList';
import RoomList from '@/views/stocks/RoomList';

export default {
  name: 'Index',
  data() {
    return {};
  },
  computed: {
    stockListComponent() {
      const { roles } = this.$store.getters;

      if (roles && roles[0] === 'HKP') {
        return RoomList;
      }

      return DefaultList;
    },
  },
};
</script>
