import { flatMap, groupBy, map } from 'lodash';

export default {
  data() {
    return {
      submitting: false,
      room: undefined,
      stocks: undefined,
      initStocks: undefined,
      size: 'large',
    };
  },
  methods: {
    async fetchData() {
      try {
        this.stocks = undefined;
        this.$store.commit('SHOW_FULLSCREEN_LOADER');

        const { list } = await this.$store.dispatch('rooms/stocks', this.room.id);

        const stocks = [];
        for (let i = 0; i < list.length; i += 1) {
          const { id, name, items } = list[i];

          const grouped = groupBy(items, 'category');
          const groupedItems = Object.keys(grouped).map((category) => {
            const collection = map(grouped[category], (item) => ({
              use: 0,
              stockId: id,
              ...item,
            }));

            return { collection, category };
          });

          stocks.push({ id, name, items: groupedItems });
        }

        this.stocks = stocks;
        this.initStocks = JSON.parse(JSON.stringify(this.stocks));
      } catch (e) {
        console.log(e);
      }
      this.$store.commit('HIDE_FULLSCREEN_LOADER');
    },
    async onSubmit() {
      const updates = [];
      const items = flatMap(this.stocks, ({ items }) => flatMap(items, 'collection'));

      for (let i = 0; i < items.length; i += 1) {
        const { id, stockId, use } = items[i];

        if (use > 0) {
          updates.push({ id, stockId, quantity: use * -1 });
        }
      }

      try {
        this.$store.commit('SHOW_FULLSCREEN_LOADER');

        await this.$store.dispatch('stocks/updateQuantities', { items: updates });
        await this.fetchData();

        this.$message.success(this.$t('success'));
      } catch (e) {
        console.log(e);
      }
      this.$store.commit('HIDE_FULLSCREEN_LOADER');
    },
    resetFields() {
      if (this.initStocks) {
        this.stocks = JSON.parse(JSON.stringify(this.initStocks));
      }
    },
  },
};
