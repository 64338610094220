<template>
  <div class="columns form is-mini-gap is-multiline">
    <div v-show="!property" class="column is-narrow">
      <InputProperty v-model:value="propertyId" style="min-width: 200px" @change="handleFilter" />
    </div>
    <div class="column is-narrow">
      <InputRoom v-model:value="roomId" style="min-width: 200px" @change="handleFilter" />
    </div>
    <div class="column">
      <a-button
        v-show="propertyId || roomId"
        :size="size"
        style="min-width: 110px"
        type="default"
        @click="handleClear"
      >
        {{ $t('Clear') }}
      </a-button>
    </div>
  </div>
</template>

<i18n>
{
"en": {
"Clear": "Clear"
},
"ja": {
"Clear": "クリア"
}
}
</i18n>

<script>
import debounce from 'lodash/debounce';
import InputProperty from '@/components/InputProperty';
import InputRoom from '@/components/InputRoom';
import { cleanObject } from '@/utils/util';

export default {
  name: 'SearchForm',
  components: {
    InputProperty,
    InputRoom,
  },
  props: {
    property: {
      type: Object,
      default: null,
    },
    size: {
      type: String,
      default: 'large',
    },
  },
  emits: ['filter'],
  data() {
    return {
      propertyId: undefined,
      roomId: undefined,
    };
  },
  computed: {},
  mounted() {
    this.propertyId = this.$route.query.propertyId || undefined;
    this.roomId = this.$route.query.roomId || undefined;
  },
  methods: {
    // eslint-disable-next-line func-names
    handleFilter: debounce(async function () {
      const query = cleanObject({
        ...this.$route.query,
        propertyId: this.propertyId,
        roomId: this.roomId,
        page: 1, // force page 1
      });

      await this.$router.push({ query });
      this.$emit('filter');
    }, 600),
    handleClear() {
      this.propertyId = undefined;
      this.roomId = undefined;
      this.handleFilter();
    },
  },
};
</script>

<style scoped></style>
