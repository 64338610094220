<template>
  <div>
    <a-select
      v-model:value="stockId"
      :filter-option="filterOption"
      :size="size"
      show-search
      aria-autocomplete="off"
      autocomplete="off"
      @change="handleChange"
    >
      <a-select-option v-for="data in stocks" :key="data.name" :value="data.id">
        {{ data.name }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
export default {
  name: 'InputInsepctionList',
  props: {
    value: {
      type: String,
      default() {
        return undefined;
      },
    },
    size: {
      type: String,
      default() {
        return 'large';
      },
    },
  },
  emits: ['change', 'update:value'],
  data() {
    return {
      stockId: null,
      stocks: [],
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        this.stockId = nv;
      },
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.$store.dispatch('stocks/list', { pageSize: 100 }).then((res) => {
        this.stocks = res.list;
      });
    },
    filterOption(input, option) {
      return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    handleChange() {
      this.$emit('change', this.stockId);
      this.$emit('update:value', this.stockId);
    },
  },
};
</script>

<style scoped></style>
