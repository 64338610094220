<template>
  <div class="column columns is-12 is-mobile secondary-item">
    <div class="column is-3 is-6-tablet">
      <a-input v-model:value="item.name" :size="size" style="width: 100%" @change="handleChange" />
    </div>
    <div class="column has-text-centered" style="min-width: 130px">
      <img
        v-if="typeof item.image === 'string' && item.image.length"
        :src="$filters.imageUrl(item.image)"
        width="100"
        height="100"
      />
      <a-upload
        v-show="!item.image || typeof item.image === 'object'"
        v-model:file-list="fileList"
        name="file"
        accept="image/png,image/jpeg"
        list-type="picture-card"
        :multiple="false"
        :before-upload="handleBeforeUploadStock"
        :remove="handleImageRemove"
        @change="handleImageChangeStock"
      >
        <a-button type="primary" :size="size">
          {{ $t('Add Image') }}
        </a-button>
      </a-upload>
    </div>
    <div class="column has-text-centered">
      <a-input-number
        v-model:value="item.quantity"
        style="width: 100%"
        min="0"
        :size="size"
        @change="handleChange"
      />
    </div>
    <div class="column has-text-centered">
      <a-input-number
        v-model:value="item.alertMargin"
        style="width: 100%"
        min="0"
        :size="size"
        @change="handleChange"
      />
    </div>
    <div class="column is-narrow">
      <DeleteFilled
        v-permission="['modify-stock-control']"
        class="has-text-danger m-l-2x"
        @click.stop="handleDelete"
      />
    </div>
  </div>
</template>

<i18n>
{
"en":{
"Add Image": "Add Image"
},
"ja":{
"Add Image": "写真を追加"
}
}
</i18n>

<script>
import { DeleteFilled } from '@ant-design/icons-vue';
import FileUploader from '@/components/mixins/FileUploader';

export default {
  name: 'StockSecondaryItem',
  components: {
    DeleteFilled,
  },
  mixins: [FileUploader],
  props: {
    value: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      default: 'large',
    },
    index: {
      type: Number,
      required: true,
    },
  },
  emits: ['change', 'delete'],
  data() {
    return {
      fileList: [],
      item: {
        _id: undefined, // internal id for :key
        id: undefined,
        name: '',
        image: '',
        quantity: 0,
        alertMargin: 0,
      },
    };
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(nv) {
        this.item._id = nv.id || nv._id || 1;
        this.item.id = nv.id;
        this.item.name = nv.name;
        this.item.image = nv.image;
        this.item.quantity = nv.quantity;
        this.item.alertMargin = nv.alertMargin;
      },
    },
  },
  methods: {
    handleBeforeUploadStock(file) {
      const isValidSize = this.checkFileSize(file);
      this.isFileValid = false;
      if (isValidSize) {
        this.fileList = [file];
        this.item.image = file;
        this.handleChange();
        this.isFileValid = true;
      }

      return false;
    },
    handleChange() {
      this.$emit('change', { item: this.item, index: this.index });
    },
    handleImageChangeStock(info) {
      if (!this.isFileValid) {
        this.fileList = [];
        return false;
      }

      const fileList = [...info.fileList];
      this.fileList = fileList.slice(-1);

      return false;
    },
    handleImageRemove() {
      this.item.image = '';
      this.handleChange();
    },
    handleDelete() {
      this.$emit('delete', this.item, this.index);
    },
  },
};
</script>

<style lang="scss" scoped>
.secondary-item {
  padding-left: 1.5rem !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.add-button-wrap {
  margin-top: $bleed * 2;
}

/deep/ .is-5 label::after {
  display: none;
}
</style>
