<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <div class="column is-12">
      <h3 class="has-text-black has-text-weight-bold is-size-4">
        {{ $t('Add Room') }}
      </h3>
      <p>{{ $t('desc', { name: value.name }) }}</p>
    </div>
    <div class="column is-12">
      <div v-for="(item, index) in form.items" :key="index" class="columns">
        <a-form-item class="column" :label="$t('Property')" name="propertyId">
          <InputProperty v-model:value="item.propertyId" />
        </a-form-item>
        <a-form-item class="column" :label="$t('Room Name')" name="roomId">
          <InputRoom
            v-model:value="item.roomId"
            :property-id="item.propertyId"
            :disabled="!item.propertyId"
          />
        </a-form-item>
        <div class="column is-1">
          <DeleteFilled
            v-permission="['modify-inspection']"
            class="has-text-danger m-l-2x"
            style="margin-top: 2rem"
            @click.stop="removeItem(index)"
          />
        </div>
      </div>
    </div>
    <div class="add-button-wrap column is-12">
      <a-button type="primary" :size="size" class="button-secondary-outline" @click="addItem">
        {{ $t('Add Room') }}
      </a-button>
    </div>
    <div class="column has-text-right is-12 m-t-4x">
      <a-button
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="loading"
        :size="size"
        style="min-width: 115px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n>
{
"en": {
"Add Room": "Add Room",
"desc": "The stock control ‘{name}’ will be applied to these rooms.",
"Property": "Property",
"Room Name": "Room Name",
"Cancel": "Cancel",
"Save": "Save"
},
"ja": {
"Add Room": "部屋を追加",
"desc": "在庫コントロール {name} はこれらの部屋に適応されます",
"Property": "物件",
"Room Name": "部屋番号",
"Cancel": "取り消し",
"Save": "保存"
}
}
</i18n>

<script>
import { DeleteFilled } from '@ant-design/icons-vue';
import InputRoom from '@/components/InputRoom';
import InputProperty from '@/components/InputProperty';

export default {
  name: 'AddRoomForm',
  components: {
    InputRoom,
    InputProperty,
    DeleteFilled,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'cancel'],
  data() {
    return {
      removedRooms: [],
      form: {
        items: [
          {
            stockId: '',
            propertyId: '',
            roomId: '',
          },
        ],
      },
      rules: {
        // todo
      },
      size: 'large',
      loading: false,
    };
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(nv) {
        if (nv) {
          const items = nv.rooms.map((item) => ({
            stockId: nv.id,
            roomId: item.id,
            propertyId: item.propertyId,
          }));

          this.form.items = items;
        }
      },
    },
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        this.loading = true;

        const roomIds = this.form.items.map((item) => item.roomId);
        const payload = {
          id: this.value.id,
          roomIds,
        };

        await this.$store.dispatch('stocks/setRooms', payload);

        this.$refs.form.resetFields();
        this.$emit('close');
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
    addItem() {
      this.form.items.push({
        stockId: '',
        roomId: '',
        propertyId: '',
      });
    },
    removeItem(index) {
      this.removedRooms.push(this.form.items[index]);
      this.form.items.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.sep {
  border-top: 1px solid #dcdcdc;
  width: 100%;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}
</style>
