<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <div class="column is-12 is-align-items-center is-flex is-justify-content-space-between">
      <h3 class="has-text-black has-text-weight-bold is-size-4">
        {{ form.id ? $t('Edit Stock Control') : $t('Add Stock Control') }}
      </h3>
      <DeleteStock
        v-if="form.id"
        v-permission="['modify-stock-control']"
        :record="form"
        @delete="fetchStocks"
      />
    </div>
    <a-form-item v-show="!form.id" class="column is-12" :label="$t('Copy from')">
      <InputStocks v-model:value="copyOfId" style="min-width: 200px" @change="handleCopyStocks" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Stock Control Name')" name="name">
      <a-input v-model:value="form.name" :size="size" />
    </a-form-item>
    <div class="sep" />
    <PrimaryItem
      v-for="(item, index) in form.items"
      :key="index"
      v-model:value="form.items[index]"
      :index="index"
      @change="
        (val) => {
          handlePrimaryItemChange(val, index);
        }
      "
      @item-removed="handleItemRemoved"
    />
    <div class="add-button-wrap column is-12">
      <a-button
        type="primary"
        :size="size"
        class="button-secondary-outline"
        @click="handleAddPrimaryItem"
      >
        {{ $t('Add Primary Item') }}
      </a-button>
      <a-button
        v-if="form.items.length > 1"
        style="margin-left: 1rem"
        type="danger"
        :size="size"
        @click="handleRemovePrimary"
      >
        {{ $t('Remove One Item') }}
      </a-button>
    </div>
    <div class="column has-text-right is-12 m-t-4x">
      <a-button
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        v-permission="['modify-inspection']"
        :loading="loading"
        :size="size"
        style="min-width: 115px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n>
{
"en": {
"Edit Stock Control": "Edit Stock Control",
"Add Stock Control": "Add Stock Control",
"Copy from": "Copy from",
"Stock Control Name": "Stock Control Name",
"Add Primary Item": "Add Primary Item",
"Remove One Item": "Remove One Item",
"Cancel": "Cancel",
"Save": "Save"
},
"ja": {
"Edit Stock Control": "在庫コントロールを編集",
"Add Stock Control": "在庫コントロールを追加",
"Copy from": "コピー",
"Stock Control Name": "在庫コントロール名",
"Add Primary Item": "大項目を追加",
"Remove One Item": "アイテムを1つ削除する",
"Cancel": "取り消し",
"Save": "保存"
}
}
</i18n>

<script>
import { groupBy } from 'lodash';
import PrimaryItem from '@/views/stocks/components/PrimaryItem';
import InputStocks from '@/views/stocks/components/InputStocks';
import DeleteStock from '@/views/stocks/components/DeleteStock';

const defaultItem = {
  _id: 1,
  name: '',
  image: '',
  quantity: 0,
  alertMargin: 0,
};

export default {
  name: 'StockForm',
  components: {
    PrimaryItem,
    InputStocks,
    DeleteStock,
  },
  props: {
    value: {
      type: Object,
      default() {
        return undefined;
      },
    },
  },
  emits: ['close', 'cancel'],
  data() {
    return {
      items: [],
      removedItems: [],
      copyOfId: undefined,
      form: {
        id: undefined,
        name: undefined,
        items: [
          {
            category: '',
            collection: [defaultItem],
          },
        ],
      },
      rules: {
        // todo
      },
      size: 'large',
      loading: false,
    };
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(nv) {
        if (nv) {
          this.form.id = nv.id;
          this.form.name = nv.name;
          if (nv.items.length) {
            this.items = JSON.parse(JSON.stringify(nv.items));
            this.form.items = JSON.parse(JSON.stringify(nv.items));
          }
        }
      },
    },
  },
  methods: {
    async handleCopyStocks(listId) {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');

      const data = await this.$store.dispatch('stocks/get', listId);
      const grouped = groupBy(data.items, 'category');

      const items = Object.keys(grouped).map((category) => {
        const collection = grouped[category].map((item) => ({
          _id: item.id,
          name: item.name,
          quantity: item.quantity,
          alertMargin: item.alertMargin,
          image: '',
        }));

        return { collection, category };
      });

      this.$store.commit('HIDE_FULLSCREEN_LOADER');

      this.form = {
        name: data.name,
        items: JSON.parse(JSON.stringify(items)),
      };
      this.items = JSON.parse(JSON.stringify(items));
    },
    async handleSubmit() {
      await this.$refs.form.validate();
      this.loading = true;
      try {
        const { images, ...payload } = this.createPayload();

        if (payload.id) {
          await this.updateStockControl(payload, images);
        } else {
          await this.createStockControl(payload, images);
        }

        this.$refs.form.resetFields();
        this.$emit('close');
      } finally {
        this.loading = false;
      }
    },
    createPayload() {
      const items = [];
      const images = [];

      for (let i = 0, imageIndex = 0; i < this.items.length; i += 1) {
        const { category, collection } = this.items[i];

        for (let j = 0; j < collection.length; j += 1) {
          const { image, ...item } = collection[j];
          const imageAttr = { itemId: item.id, image };

          if (!item.id) {
            imageAttr.newIndex = imageIndex;
            imageIndex += 1;
          }

          images.push(imageAttr);
          items.push({ category, ...item });
        }
      }

      return {
        id: this.form.id,
        name: this.form.name,
        items,
        images,
      };
    },
    async createStockControl(payload, images) {
      const { data } = await this.$store.dispatch('stocks/add', payload);
      const { id, items } = data;

      await this.uploadImages(id, images, items);
    },
    async updateStockControl(payload, images) {
      const { data } = await this.$store.dispatch('stocks/update', payload);
      const { updated, created } = data;

      await this.uploadImages(updated.id, images, created);
      // deleted items
      const deletedIds = this.removedItems.map((removed) => removed.id);
      await this.$store.dispatch('stocks/deleteItems', { stockId: updated.id, ids: deletedIds });
    },
    uploadImages(stockId, images, newItems) {
      const upload = [];

      for (let i = 0; i < images.length; i += 1) {
        const { itemId, image, newIndex } = images[i];

        if (image && typeof image === 'object') {
          const imagePayload = {
            stockId,
            id: itemId,
            file: image,
          };

          // images for new items
          if (!itemId && newItems[newIndex] !== undefined) {
            imagePayload.id = newItems[newIndex].id;
          }

          upload.push(this.$store.dispatch('stocks/setItemImage', imagePayload));
        }
      }

      return Promise.all(upload);
    },
    handlePrimaryItemChange(val, index) {
      this.items[index] = val;
    },
    handleAddPrimaryItem() {
      const primary = {
        category: '',
        collection: [defaultItem],
      };

      this.form.items.push(primary);
    },
    handleRemovePrimary() {
      const item = this.form.items.pop();
      const collection = item ? item.collection : [];

      for (let i = 0; i < collection.length; i += 1) {
        const item = collection[i];

        if (item.id) {
          this.removedItems.push(item);
        }
      }
    },
    handleItemRemoved(item) {
      if (item.id) {
        this.removedItems.push(item);
      }
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
.sep {
  border-top: 1px solid #dcdcdc;
  width: 100%;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}
</style>
