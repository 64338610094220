<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column">
              <h2 class="is-3 title">
                {{ $t('Stock Controls') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container">
        <div class="columns is-mobi">
          <div class="column">
            <SearchForm @filter="fetchStocks" />
          </div>
        </div>
        <a-table
          row-key="id"
          :columns="columns"
          :data-source="list"
          :loading="isFetching"
          :custom-row="rowClicked"
          :pagination="pagination"
          @change="handleTableChange"
        >
          <template #id="{ text }">
            {{ text }}
          </template>
          <template #property="{ record }">
            {{ record.property ? record.property.abbreviation : '' }}
          </template>
          <template #name="{ record }">
            {{ record.name }}
          </template>
          <template #stockControl="{ record }">
            <ul v-if="record.stocks.length" class="room-stocks">
              <li v-for="stock in record.stocks" :key="stock">
                {{ stock.name }}
              </li>
            </ul>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<i18n>
{
"en": {
"Stock Controls": "Stock Controls",
"Property": "Property",
"Room": "Room",
"Stock Control Name": "Stock Control Name"
},
"ja": {
"Stock Controls": "在庫コントロール",
"Property": "物件",
"Room": "部屋",
"Stock Control Name": "在庫コントロール名"
}
}
</i18n>

<script>
import list from '@/views/stocks/mixins/list';
import SearchForm from '@/views/stocks/components/SearchForm';

export default {
  name: 'RoomList',
  components: {
    SearchForm,
  },
  mixins: [list],
  data() {
    return {
      columns: [
        {
          title: '#',
          dataIndex: '_id',
          key: '_id',
          slots: { customRender: 'id' },
        },
        {
          title: this.$t('Property'),
          dataIndex: 'property',
          slots: { customRender: 'property' },
        },
        {
          title: this.$t('Room'),
          dataIndex: 'name',
          slots: { customRender: 'name' },
        },
        {
          title: this.$t('Stock Control Name'),
          dataIndex: 'stockControl',
          slots: { customRender: 'stockControl' },
        },
      ],
    };
  },
  mounted() {
    this.fetchStocks();
  },
  methods: {
    rowClicked(record) {
      return {
        onClick: () => {
          this.$router.push({ name: 'room-stocks', params: { id: record.id } });
        },
      };
    },
  },
};
</script>
