<template>
  <Delete
    action-handler="stocks/delete"
    :payload-value="record.id"
    :title="$t('title')"
    :description="$t('desc', { name: record.name })"
    :success-title="$t('deleted-title')"
    :success-description="$t('deleted-desc', { name: record.name })"
    @close="$emit('delete')"
  />
</template>

<i18n>
{
"en": {
"title": "Delete Stock Control?",
"desc": "Are you use you want to delete stock control <strong>{name}</strong> ?",
"deleted-title": "Stock Control Deleted",
"deleted-desc": "Stock control <strong>{name}</strong> has been removed."
},
"ja": {
"title": "在庫コントロールを削除?",
"desc": "本当に <strong>{name}</strong> を削除しますか？",
"deleted-title": "在庫コントロール削除完了",
"deleted-desc": "在庫コントロール <strong>{name}</strong> が削除されました"
}
}
</i18n>

<script>
import Delete from '@/components/PopupDelete';

export default {
  name: 'DeleteStock',
  components: {
    Delete,
  },
  props: {
    record: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['delete'],
};
</script>
